<template>
  <main class="layout-main">
    <div class="inner">
      <div class="container-wrapper">
        <div class="layout-title">
          <h1 class="heading-page"><img src="@/assets/image/title/service.svg" alt="Service"><span class="sub">事業内容</span></h1>
        </div>
      </div>

      <section class="container-bg">
        <div class="copy-fukidashi -service"><p class="comment"><img src="@/assets/image/service/fukidashi.svg" alt="できること"></p></div>

        <div class="container-wrapper -pad">
          <div class="tab-contents">
            <div class="contents">
              <div v-if="isActive === 'businessModel'"><img src="@/assets/image/service/model.png" alt="Business Model" class="illust _pc"><img src="@/assets/image/service/model_sp.png" alt="Business Model" class="illust _tb _sp"><p>One Betterは「一枚上手」な発想・企画・繋がりで、ワクワクするコミュニケーションプランの支援を行なっています。</p></div>
              <div v-else-if="isActive === 'strongPoint'"><img src="@/assets/image/service/point.png" alt="One Betterの強み" class="illust _pc"><img src="@/assets/image/service/point_sp.png" alt="One Betterの強み" class="illust _tb _sp"><p>繋がりが財産。あらゆる繋がりを活かし、プロジェクト毎に「人」を最適化</p></div>
              <div v-else-if="isActive === 'philosophy'"><img src="@/assets/image/service/mvv.png" alt="理念・ビジョン・バリュー" class="illust"><p>多岐にわたる経験を活かした、今だからこそ出来る表現や企画を実現して<br class="_pc">インフルエンサー施策やモノづくりからプロモーション全般まで、領域を跨いだコミュニケーションのきっかけをつくります。</p></div>
            </div>

            <ul class="menu">
              <li @click="changeTab('businessModel')" v-bind:class="{'-active': isActive === 'businessModel'}">Business<br class="_sp"> Model</li>
              <li @click="changeTab('strongPoint')" v-bind:class="{'-active': isActive=== 'strongPoint'}">One Better<br class="_sp">の強み</li>
              <li @click="changeTab('philosophy')" v-bind:class="{'-active': isActive === 'philosophy'}">理念・ビジョン<br class="_sp">・バリュー</li>
            </ul>

          </div>
        </div>
      </section>


      <section id="service-menu" ref="serveMenu">
        <div class="container-wrapper -bottom">
          <div class="layout-title -sub">
            <h2 class="heading-page -section"><img src="@/assets/image/title/menu.svg" alt="Service Menu"><span class="sub">サービスメニュー</span></h2>

          </div>

          <ul class="grid-block -cols3 -center">
            <li>
              <div class="card-block -servicemenu">
                <router-link to="/works/planning">
                <div class="thumb">
                  <svg width="0" height="0" style="position: absolute; top: 0; left: 0;">
                    <clipPath id="menu_kikau">
                      <path class="cls-1" d="M187.79,79c-2.13,0-4.25.06-6.34.2V16.52H20.17V177.79H91.79a96,96,0,1,0,96-98.75Z"/>
                    </clipPath>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 304 282">
                    <image xlink:href="@/assets/image/service/01.jpg" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" clip-path="url(#menu_kikau)"/>
                  </svg>
                </div>
                <div class="body">
                <h4 class="title">企画・発想</h4>
                <p>企画・発想を得意とし、例えば、四角い難しい課題を丸く表現することで、直感的に分かりやすいコミュニケーションの創出など、プロモーション全般やデジタルマーケティング、プロダクト開発に映像企画などあらゆる領域において、一枚上手な独特のアイデアをご提供させて頂きます。</p></div>
              </router-link>
              </div>
            </li>
            <li>
              <div class="card-block -servicemenu">
                <router-link to="/works/promotion">
                  <div class="thumb">
                    <svg width="0" height="0" style="position: absolute; top: 0; left: 0;">
                      <clipPath id="menu_promotion">
                        <path class="cls-1" d="M226.49,100.89A84.36,84.36,0,0,1,225.15,116a73.71,73.71,0,0,1-23.84,143.45h0a73.4,73.4,0,0,1-50.26-19.8A61.56,61.56,0,0,1,39,204.43h0a61.58,61.58,0,0,1,33.2-54.66,84.89,84.89,0,1,1,154.29-48.88Z"/>
                      </clipPath>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 304 282">
                      <image xlink:href="@/assets/image/service/02.jpg" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" clip-path="url(#menu_promotion)"/>
                    </svg>
                  </div>
                  <div class="body">
                    <h4 class="title">プロモーション</h4>
                    <p>届けたい人や届けたいところへ、手法にとらわれない、一枚上手な切り口によるSNS上での話題化や、想いやメッセージもちゃんと伝わるコミュニケーションプランなど、戦略、クリエイティブ、メディアプランニング、PRまでお任せください。</p></div>
                  </router-link>
              </div>
            </li>
            <li>
              <div class="card-block -servicemenu">
                <router-link to="/works/sns">
                <div class="thumb">
                  <svg width="0" height="0" style="position: absolute; top: 0; left: 0;">
                    <clipPath id="menu_sns">
                      <path class="cls-1" d="M213.09,258H28V77H90.9V24H276V204.05H213.09V258Z"/><path class="cls-1" d="M91.4,24.5v53H28.5v180H212.59V203.55H275.5V24.5H91.4m-1-1H276.5V204.55H213.59V258.5H27.5v-182H90.4Z"/>
                    </clipPath>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 304 282">
                    <image xlink:href="@/assets/image/service/03.jpg" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" clip-path="url(#menu_sns)"/>
                  </svg>
                </div>
                <div class="body">
                <h4 class="title">デジタルマーケティング</h4>
                <p>各SNSやプラットフォームでの導線やエンゲージ、アウトプットを意識したYouTube運用にタイアップキャスティングによるマーケティングや、キャラクター性を持たせたSNSアカウント運用によるブランディングのお手伝いなども可能です。</p></div>
                </router-link>
              </div>
            </li>
            <li>
              <div class="card-block -servicemenu">
                <router-link to="/works/casting">
                <div class="thumb">
                  <svg width="0" height="0" style="position: absolute; top: 0; left: 0;">
                    <clipPath id="menu_casting">
                      <path class="cls-1" d="M87.79,263.22l160.8-21.34L279,31.05,35.47,13.63Z"/>
                    </clipPath>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 304 282">
                    <image xlink:href="@/assets/image/service/04.jpg" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" clip-path="url(#menu_casting)"/>
                  </svg>
                </div>
                <div class="body">
                <h4 class="title">キャスティング</h4>
                <p>インフルエンサー、芸人さん、タレント、アスリートなど共通言語を持った効率の良い窓口連携に、企画と合わせたキャスティングをお任せください。</p></div>
                </router-link>
              </div>
            </li>
            <li>
              <div class="card-block -servicemenu">
                <router-link to="/works/develop">
                <div class="thumb">
                  <svg width="0" height="0" style="position: absolute; top: 0; left: 0;">
                    <clipPath id="menu_dev">
                      <path id="合体_2" data-name="合体 2" class="cls-1" d="M105.32,173.14a81.53,81.53,0,1,1,73.36-89c.16,1.68.27,3.36.33,5a92,92,0,1,1-73.69,83.91Z"/>
                    </clipPath>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 304 282">
                    <image xlink:href="@/assets/image/service/05.jpg" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" clip-path="url(#menu_dev)"/>
                  </svg>
                </div>
                <div class="body">
                <h4 class="title">事業開発</h4>
                <p>未来の柱になる新たな挑戦を現実的なものにすべく、事業者側の目線や、ターゲットの立場両側に立って事業としての確度を最大限高め、計画から実行、ランニングまでをご一緒させて頂きます。</p></div>
                </router-link>
              </div>
            </li>
          </ul>

        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { onMounted, onUpdated, onUnmounted, ref} from 'vue'
import { useRoute, useRouter } from 'vue-router'
export default {

  emits: ['scrollTo'],
  props: {
    msg: {
      type: String,
      default: 'default Value',
    },
  },
  setup( props, { emit }) {
    const router = useRouter()
    const route = useRoute()

    const isActive = ref('businessModel');
    const serveMenu = ref()

    const changeTab = ( num ) => {
      isActive.value = num
    }

    onMounted(() => {
      if(route.hash){
        emit("scrollTo" , serveMenu.value )
      }
    })
    onUpdated(() => {
      //console.log('updated!')
    })
    onUnmounted(() => {
      //console.log('unmounted!')
    })
    return{
      changeTab,
      isActive,
      router,
      route,
      serveMenu,
      //scrollTo
    }
  },

}

</script>